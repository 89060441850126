<template>
  <div v-if="loading" class="Warmup Warmup--loading" />
  <CountdownScreen
    v-else-if="countdown"
    class="SessionView SessionView--countdown"
    @countdownFinished="onCountdownDone()"
  />

  <div v-else class="Warmup">
    <SegmentedProgressBar :segments="segments" :styles="{ correct: '#93c60b', wrong: '#cd0a10' }" />
    <div class="header">
      <div class="timer"> {{ $t('timeUsed') }}: {{ timeUsed }} </div>
    </div>
    <div class="spacer" />

    <FallingItemsCanvas ref="fallingItemsCanvas" @item-timeout="onItemTimeout">
      <template slot="item" slot-scope="props">
        <FallingItemContent v-bind="items[props.index]" />
      </template>
    </FallingItemsCanvas>

    <div class="optionsSection">
      <transition name="slide" mode="out-in">
        <div v-if="currentBatch" :key="currentBatch.id" class="options" :class="{ empty: currentBatch.allMatched }">
          <GameChoiceItem
            v-for="option of currentBatch.options"
            :key="option.pairId"
            v-bind="option"
            class="Option"
            @click="selectOption(option)"
          />
        </div>
      </transition>
    </div>

    <div class="spacer" />
  </div>
</template>

<translations>
  timeUsed: 'Time used'
  timeUsed_no: 'Tid'
</translations>

<script>
import shuffle from 'lodash-es/shuffle'
import memoransierData from '../data'
import SegmentedProgressBar from '@/components/SegmentedProgressBar'
import FallingItemsCanvas from '../components/FallingItemsCanvas'
import FallingItemContent from '../components/FallingItemContent'
import GameChoiceItem from '../components/GameChoiceItem'
import CountdownScreen from './QuizCountdown'
import { wait } from '@/utils'
import { mapGetters } from 'vuex'
import quizHelper from '@/modules/course/quiz-helper'
// import soundService from '@/services/sound-service'
import bgMusic from '../data/audio/quizMusic.mp3'
import correctSound from '@/assets/sounds/correct-answer.mp3'
import wrongSound from '@/assets/sounds/wrong-answer.mp3'

export default {
  components: { SegmentedProgressBar, FallingItemsCanvas, FallingItemContent, GameChoiceItem, CountdownScreen },

  props: {
    gameId: {
      required: true,
    },
  },
  data() {
    return {
      questionIndex: -1,
      batchIndex: -1,
      numberOfItems: 0,
      segments: [],
      batches: [],
      items: [],
      timeUsed: 0,
      correctCount: 0,
      running: false,
      ready: false,
      loading: true,
      countdown: true,
      timeIntervalId: null,
      refreshId: null,
      sounds: [bgMusic, correctSound, wrongSound],
    }
  },

  computed: {
    ...mapGetters({ getGameById: 'moduleMemoransier/getById' }),
    currentItem() {
      return this.items[this.questionIndex]
    },
    currentBatch() {
      return this.batches[this.batchIndex] || null
    },
    game() {
      return this.getGameById(this.gameId)
    },
  },
  async mounted() {
    // soundService.preload(this.sounds);
    await this.$store.dispatch('moduleMemoransier/refresh')
    const game = this.$store.state.moduleMemoransier.games.find(g => g.id === this.gameId)
    const options = game.options || {}

    if (options.dataId) {
      await this.initialize(memoransierData[options.dataId])
    } else {
      const stepGroupId = options.stepGroupId
      const sg = this.$store.getters['moduleCourse/getJourneyById'](stepGroupId)
      const courseId = sg.courseId
      await this.$store.dispatch('moduleCourse/loadCourseFull', { courseId })
      await this.initialize(this.memosForJourney(stepGroupId))
    }
    await wait(2000)
    this.loading = false
    this.refresh()
  },

  beforeDestroy() {
    clearInterval(this.intervalId)
    clearTimeout(this.refreshId)
    // soundService.stop(bgMusic, 500);
  },

  methods: {
    start() {
      this.running = true

      this.intervalId = setInterval(() => {
        this.timeUsed += 1
      }, 1000)
      this.next()
    },
    async next() {
      if (this.questionIndex !== -1) {
        this.updateProgressBar()
      }
      // this.reportState();

      if (this.questionIndex === this.numberOfItems - 1) {
        this.end()
        return
      }

      if (this.currentItem && this.currentItem.state === 'correct') {
        await wait(500) // NOTE: Give some dedicated time for feedback animation
      }
      await this.updateBatch()

      this.questionIndex += 1
      this.$refs.fallingItemsCanvas.release()
      this.ready = true
    },

    async updateBatch() {
      const currentItem = this.currentItem
      const nextItem = this.items[this.questionIndex + 1]
      const batchDone = currentItem && nextItem && currentItem.batchIndex !== nextItem.batchIndex

      if (this.batchIndex === -1) {
        this.batchIndex = nextItem.batchIndex
        await wait(100) // NOTE: Give some time for enter animation of next batch
      } else if (batchDone) {
        this.batchIndex = nextItem.batchIndex
        if (!this.batches[currentItem.batchIndex].allMatched) {
          await wait(500) // NOTE: Give some time for exit animation of current batch
        }
        await wait(100) // NOTE: Give some time for enter animation of next batch
      }
    },
    updateProgressBar() {
      this.updatePlayer()
      const segment = this.segments[this.questionIndex]
      if (this.currentItem.state === 'correct') {
        // soundService.play(correctSound)
        segment.style = 'correct'
        this.correctCount++
      } else {
        // soundService.play(wrongSound)
        segment.style = 'wrong'
      }
      segment.done = true
    },

    end() {
      this.running = false
      this.exit()
    },
    onItemTimeout(itemIndex) {
      const item = this.items[itemIndex]
      if (item.state) {
        return
      }
      item.state = 'timeout'
      this.next()
    },
    selectOption(option) {
      if (!this.ready) {
        return
      }
      const item = this.items[this.questionIndex]
      this.ready = false
      if (item.pairId === option.pairId) {
        // soundService.play(correctSound)
        this.$refs.fallingItemsCanvas.stop()
        item.state = 'correct'
        option.matched = true
        this.currentBatch.allMatched = this.currentBatch.options.every(o => o.matched)
      } else {
        // soundService.play(wrongSound)
        item.state = 'wrong'
        option.wrongCount++
      }
      this.next()
    },
    onCountdownDone() {
      this.countdown = false
      this.start()
      // soundService.play(bgMusic, { volume: 0.2, loop: true }, 'background');
    },

    async initialize(gameData) {
      this.batches = gameData.memos.map((memo, batchIndex) => {
        const items = memo.pairs.map((pair, i) => {
          return {
            pairId: `${batchIndex}-${i}`,
            text: pair.text,
            state: '',
            batchIndex,
          }
        })
        this.items = this.items.concat(items)

        const options = memo.pairs.map((pair, i) => {
          return {
            pairId: `${batchIndex}-${i}`,
            image: pair.image,
            matched: false,
            wrongCount: 0,
          }
        })
        if (memo.fakeOptions) {
          memo.fakeOptions.forEach((fakeOption, i) => {
            options.push({
              pairId: `fake-${i + 1}`,
              image: fakeOption,
              matched: false,
              wrongCount: 0,
            })
          })
        }

        return {
          id: `batch-${batchIndex}`,
          allMatched: false,
          options: shuffle(options),
        }
      })
      this.numberOfItems = this.items.length
      this.segments = this.items.map(() => ({ done: false, style: '' }))
    },

    async refresh() {
      clearTimeout(this.refreshId)

      await this.$store.dispatch('moduleMemoransier/refresh')
      const game = this.$store.state.moduleMemoransier.games.find(g => g.id === this.gameId)
      const isGameFinished = game && game.leaderboardReveal

      if (isGameFinished) {
        this.exit()
        return
      }

      this.refreshId = setTimeout(() => this.refresh(), 1000)
    },

    updatePlayer() {
      this.$store.dispatch('moduleMemoransier/updatePlayer', {
        gameId: this.gameId,
        correct: this.correctCount,
        completed: this.questionIndex === this.numberOfItems - 1,
        time: { seconds: this.timeUsed },
      })
    },

    exit() {
      this.$router.push({
        name: 'MemoransierPodium',
        props: {
          gameId: this.gameId,
        },
      })
    },

    memosForJourney(stepGroupId) {
      const dp = quizHelper.createQuestionProvider(
        { generator: 'memoGameA' },
        { stepGroupId, lstore: this.$store, activityName: 'memorize', options: null }
      )
      const all = dp.items.map(i => ({ pairs: i.items.map(p => ({ image: p.image, text: p.target })) }))
      return { memos: all }
    },
  },
}
</script>

<style scoped lang="scss">
.Warmup {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);
  color: white;
}
.SegmentedProgressBar {
  flex-shrink: 0;
}
.header {
  height: $topBarHeight;
  position: relative;
  flex-shrink: 0;
}
.exitButton {
  position: absolute;
  top: 0;
  right: 1em;
  padding: 0 1em;
  height: $topBarHeight;
  display: flex;
  align-items: center;
  .svg-icon {
    $size: 0.8em;
    display: block;
    width: $size;
    height: $size;
    fill: white;
  }
}
.timer {
  position: absolute;
  top: 0;
  left: 2em;
  height: $topBarHeight;
  display: flex;
  align-items: center;
  color: rgba(white, 0.5);
}
.spacer {
  flex: 1;
  min-height: 1em;
}
.gameBox {
  width: calc(100% - 4em);
  max-width: 40em;
  align-self: center;
  flex: 1;

  height: 100%;
}
.FallingItemsCanvas {
  width: calc(100% - 4em);
  align-self: center;
  max-width: 40em;
  height: 100vh;
  max-height: 31em;
  border-bottom: 1px solid rgba(white, 0.05);
}
.optionsSection {
  flex-shrink: 0;
  position: relative;
  margin-top: 1em;
}

.options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  $transitionDuration: 0.3s;

  &:not(.empty) {
    &.slide-leave-active {
      transition: opacity $transitionDuration, transform $transitionDuration;
    }
    &.slide-leave-to {
      opacity: 0;
      transform: translate3d(0, 3em, 0);
    }
  }
}
.Option {
  $size: 5em;
  width: $size;
  height: $size;
  margin-top: 1em;

  &:not(:first-child) {
    margin-left: 1em;
  }
}
</style>
